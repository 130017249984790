<template>
  <div>
    <custom-app-breadcrumb
      :page-title="userName"
      :breadcrumb="breadcrumb"
    />
    <b-row>
      <b-col
        md="6"
      >
        <b-card v-if="user">
          <validation-observer
            ref="simpleRules"
            v-slot="{ invalid }"
          >
            <b-form>
              <b-form-group
                label="Nombre del Usuario"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Nombre"
                  rules="required"
                >
                  <b-form-input
                    v-model="user.name"
                    :state="errors.length > 0 ? false:null"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>

              </b-form-group>
              <b-form-group
                label="Correo electrónico"
                disabled
              >
                <b-form-input
                  v-model="user.email"
                />
              </b-form-group>
              <b-form-group
                label="Teléfono"
              >
                <b-form-input
                  v-model="user.phone"
                />
              </b-form-group>
              <b-form-group
                label="Tipo de Usuario"
                disabled
              >
                <b-form-input
                  v-model="userTypeText"
                />
              </b-form-group>
              <b-form-group
                v-show="user.network.id !== ''"
                label="Red"
                disabled
              >
                <b-form-input
                  v-model="user.network.name"
                />
              </b-form-group>
              <b-form-checkbox
                v-model="isUserEnabled"
                :value="true"
                name="check-button"
                switch
                class="mt-2 mb-1"
              >
                {{ isUserEnabledText }}
              </b-form-checkbox>
              <b-button
                variant="success"
                :disabled="saving || invalid"
                @click.prevent="onSaveClick"
              >
                Guardar
                <b-spinner
                  v-if="saving"
                  small
                />
              </b-button>
            </b-form>
          </validation-observer>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card
          v-if="isNetworkClient"
          :title="user.network.name"
        >
          <b-card-body>
            <ul>
              <li
                v-for="o in organizations"
                :key="o['.key']"
              >
                {{ o.name }}
              </li>
            </ul>
          </b-card-body>
        </b-card>
        <contact-list-card
          v-if="user"
          :user-id="user.id"
          :user-name="user.name"
        />
        <b-card
          v-if="isAdvisor && organizations.length > 0"
          title="Asesor de"
        >
          <b-card-body>
            <ul>
              <li
                v-for="o in organizations"
                :key="o['.key']"
              >
                <b-link
                  :to="{ name: 'admin-organizations-edit', params: { id: o.organizationId }}"
                >
                  {{ o.name }}
                </b-link>
              </li>
            </ul>
          </b-card-body>
        </b-card>
        <reset-password
          v-if="user"
          :uid="user.id"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BRow,
  BCol,
  BButton,
  BSpinner,
  BCardBody,
  BLink,
} from 'bootstrap-vue'

import { required, email } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import organizations from '@/services/organizations'
import users from '../../../services/users'
import CustomAppBreadcrumb from '../../../layouts/components/CustomAppBreadcrumb.vue'
import ResetPassword from './ResetPassword.vue'
import ContactListCard from './ContactListCard.vue'

export default {
  components: {
    CustomAppBreadcrumb,
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BCardBody,
    BLink,
    ValidationObserver,
    ValidationProvider,
    ResetPassword,
    ContactListCard,
  },
  data() {
    return {
      user: null,
      isUserEnabled: true,
      saving: false,
      organizations: [],
      required,
      email,
    }
  },
  computed: {
    userName() {
      return (this.user) ? this.user.name : ''
    },
    breadcrumb() {
      return [
        {
          text: 'Admin de Sistema',
          active: true,
        },
        {
          text: 'Usuarios',
          to: { name: 'admin-users' },
        },
        {
          text: this.userName,
          active: true,
        },
      ]
    },
    userTypeText() {
      return (this.user.type === 'ClienteRed') ? 'Cliente Red' : this.user.type
    },
    isNetworkClient() {
      return (this.user && this.user.type === 'ClienteRed')
    },
    isClient() {
      return (this.user && this.user.type === 'Cliente')
    },
    isAdvisor() {
      return (this.user && this.user.type === 'Asesor')
    },
    isUserEnabledText() {
      return (this.isUserEnabled) ? 'Usuario habilitado' : 'Usuario deshabilitado'
    },
  },
  async mounted() {
    await this.fetch()
  },
  methods: {
    async fetch() {
      try {
        const result = await users.get(this.$route.params.id)

        if ('result' in result && result.result === 'ok') {
          this.user = result.userInfo
          this.isUserEnabled = !(this.user.isDisabled)

          switch (this.user.type) {
            case 'Cliente':
              this.organizations = await organizations.getAllByContactId(this.user.id)
              break
            case 'Asesor':
              this.organizations = await organizations.getAllByAdvisorId(this.user.id)
              break
            case 'ClienteRed':
              this.organizations = await organizations.getAllByNetworkId(this.user.network.id)
              break
            default:
              this.organizations = []
          }
        } else {
          this.showNotification('Error al cargar información del usuario', result.error, 'AlertCircleIcon', 'danger')
        }
      } catch (err) {
        this.showNotification('Error al cargar información del usuario', err.message, 'AlertCircleIcon', 'danger')
      }
    },
    onSaveClick() {
      this.user.isDisabled = !(this.isUserEnabled)
      this.$refs.simpleRules.validate().then(async success => {
        if (success) {
          this.saving = true
          try {
            this.result = await users.update(this.$route.params.id, this.user)
            this.showNotification('Listo', 'La información se guardó exitosamente', 'CheckIcon', 'success')
          } catch (err) {
            this.showNotification('Error al guardar', err.message, 'AlertCircleIcon', 'danger')
          } finally {
            this.saving = false
          }
        }
      })
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
