<template>
  <div>
    <b-card title="Contacto en:">
      <div class="text-center">
        <b-spinner
          v-show="loading"
          style="width: 3rem; height: 3rem;"
          class="mt-4 mb-3"
        />
      </div>
      <b-list-group class="mt-2">
        <b-list-group-item
          v-for="c in contacts"
          :key="c.id"
          :to="{name: 'admin-organizations-edit', params: {id: c.organization.id}}"
          class="d-flex justify-content-between align-items-center"
        >
          {{ c.organization.name }}
        </b-list-group-item>
      </b-list-group>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BListGroup,
  BListGroupItem,
  BSpinner,
} from 'bootstrap-vue'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import organizationContacts from '../../../services/organizationContacts'

export default {
  components: {
    BCard,
    BListGroup,
    BListGroupItem,
    BSpinner,
  },
  props: {
    userId: {
      type: String,
      default: '',
    },
    userName: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      contacts: [],
      loading: false,
      saving: false,
      showUserSelect: false,
      deleting: false,
    }
  },
  async mounted() {
    await this.fetchContacts()
  },
  methods: {
    async fetchContacts() {
      this.loading = true
      try {
        this.contacts = await organizationContacts.getAllByUserId(this.userId)
      } catch (err) {
        this.showNotification('Error al obtener lista de organizaciones', err.message, 'AlertCircleIcon', 'danger')
      } finally {
        this.loading = false
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
