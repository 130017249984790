<template>
  <b-card title="Restablecer contraseña">
    <validation-observer
      ref="resetPasswordRules"
      v-slot="{ invalid }"
    >
      <b-form>
        <b-form-group
          label="Nueva contraseña"
        >
          <validation-provider
            #default="{ errors }"
            name="Contraseña"
            rules="required"
          >
            <b-form-input
              v-model="newPassword"
              type="password"
              :state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>
        <b-button
          variant="success"
          :disabled="saving || invalid"
          @click.prevent="onSaveClick"
        >
          Guardar
          <b-spinner
            v-if="saving"
            small
          />
        </b-button>
      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BCard, BForm, BFormGroup, BFormInput, BButton, BSpinner,
  // BCardBody, BLink,
} from 'bootstrap-vue'
import { required } from '@validations'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import users from '../../../services/users'

export default {
  components: {
    BCard,
    BForm,
    BFormGroup,
    BFormInput,
    BButton,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newPassword: '',
      saving: false,
      required,
    }
  },
  methods: {
    async onSaveClick() {
      try {
        const success = await this.$refs.resetPasswordRules.validate()
        if (success) {
          this.saving = true
          try {
            const result = await users.resetPassword(this.uid, this.newPassword)
            if ('result' in result && result.result === 'ok') {
              this.showNotification('Listo', 'La contraseña fue restablecida exitosamente', 'CheckIcon', 'success')
            } else {
              this.showNotification('Error al restablecer contraseña', result.error, 'AlertCircleIcon', 'danger')
            }
          } catch (err) {
            this.showNotification('Error al restablecer contraseña', err.message, 'AlertCircleIcon', 'danger')
          } finally {
            this.saving = false
          }
        }
      } catch (err) {
        this.showNotification('Error al validar nueva contraseña', err.message, 'AlertCircleIcon', 'danger')
      }
    },
    showNotification(sTitle, sText, sIcon, sVariant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: sTitle,
          text: sText,
          icon: sIcon,
          variant: sVariant,
        },
      })
    },
  },
}
</script>
